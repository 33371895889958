export default {
  data() {
    return {
      idle: false,
    };
  },

  created() {
    Bus.$on('idle:toggle', () => {
      this.idle = !this.idle;
    });

    Bus.$on('idle:on', () => {
      this.idle = true;
    });

    Bus.$on('idle:off', () => {
      this.idle = false;
    });
  },

  watch: {
    idle(state) {
      // console.log(`idle:${state}`);
      if (state) {
        $('#search-panel').fadeOut();
        $('#webamp').fadeOut();
        $('#media-buttons').fadeOut();
      } else {
        $('#search-panel').fadeIn();
        $('#webamp').fadeIn();
        $('#media-buttons').fadeIn();
      }
    },
  },

  beforeDestroy() {
    Bus.$off('idle:toggle');
    Bus.$off('idle:on');
    Bus.$off('idle:off');
  },
};
