<template>
  <div>
    <SearchPanel />
    <Webamp
      v-if="songs.length > 0"
      :initial-songs="songs"
      :config="config"
    />
    <MediaButtons />
  </div>
</template>

<script>
import { sync } from 'vuex-pathify';

import events from '@/mixins/events';
import idle from '@/mixins/idle';

export default {

  components: {
    SearchPanel: () => import('@/components/SearchPanel'),
    Webamp: () => import('@/components/Webamp'),
    MediaButtons: () => import('@/components/MediaButtons'),
  },

  mixins: [
    events, //
    idle, //
  ],

  computed: {
    ...sync([
      'webamp/playlist', //
    ]),

    config() {
      return {
        show: {
          eq: true,
          playlist: true,
          milkdrop: true,
        },
      };
    },
  },

  mounted() {
    if (this.$route.hash !== '') {
      // console.log('fetching songs by hash', this.$route.hash);
      Bus.$emit('songs:fetch', {
        hash: this.$route.hash,
      });
    } else {
      if (this.playlist === '') {
        this.$store.set('webamp/playlist', this.$config.defaultPlaylist);
      }

      Bus.$emit('songs:fetch', {
        playlist: this.playlist,
      });
    }
  },
};
</script>
